import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import SEO from '../components/seo/SEO';


export default function StrategicAlignment () { 

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "strategic-alignment/bkg--strategic-alignment.png"}) {
        childImageSharp {
          fixed(width: 1920, height: 1080, quality: 80) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }  
  `);

  return (
    <>
      <SEO title='Srategic Alignment' />
      <Img fixed={ data.file.childImageSharp.fixed } alt='Strategic Alignment' />
    </>
  );

}
